import { common } from './common'
export const features = {
  afterConfirm: {
    AfterConfirmChangeRequestModal: {
      title: common.requestAlternative,
      cancelButton: common.cancel,
      confirmButton: common.confirm,
      messageTitle: common.messageToOrganizer,
      emailAddressTitle: common.emailAddress,
      nameTitle: common.name,
      sendChangeRequestEmail: 'Spir will send an email to the organizer to request alternate slots',
      letsSendMessageToOrganizer: 'Message'
    },
    AfterConfirmCancelModal: {
      cancelMessageTitle: common.messageToOrganizer,
      returnButton: common.back,
      cancelButton: common.cancel
    }
  },
  arrangements: {
    ArrangementTypeSection: {
      arrangeType: 'Select the event type',
      personalSectionTitle: common.personal,
      teamSectionTitle: common.team
    }
  },
  auth: {
    AuthSignUpFormStepper: {
      items: {
        '1st': 'Sign up',
        '2nd': 'Connect a calendar'
      }
    },
    AuthFormSignUpBox: {
      title: common.register,
      body: {
        title: 'Start scheduling today'
      }
    },
    AuthButtons: {
      title: 'Sign up with {type}'
    },
    AuthGuestRegistrationModalOpenButton: {
      modalTitle: 'Manage your calendar',
      canNotShowEvent: "You can't add events in the preview"
    },
    AuthAddCalendarAuthorizationDescription: {
      security: 'Only you can see your calendar.',
      permission: 'We need to view and edit your calendar in order to show your availability and add new events.'
    },
    AuthGuestAddCalendarModal: {
      title: 'Overlay your calendar',
      introduction: {
        google: 'Spir needs your permission to access your calendar.',
        microsoft: 'Spir needs your permission to access your calendar.'
      },
      buttons: {
        cancel: 'Cancel',
        confirm: 'Sign up'
      }
    },
    AuthCalendarSyncInfo: {
      caution: 'Only you can see your calendar.',
      desc: 'Sign up to overlay these time slots on your own calendar.',
      title: 'Let Spir find the best time for you'
    },
    AuthTeamAdminAccountBox: {
      accountSituationTitle: {
        authButtons: 'Log in with your Spir account to get started',
        currentAccount: 'Your Spir account'
      },
      authButtonsDesc: {
        text: 'This will be the administrator account for your team. Don’t worry, you can edit this anytime.',
        linksText:
          'Read our<a class="link" href="{termLink}" target="_blank" rel="noopener noreferrer">terms</a> and <a class="link" href="{policyLink}" target="_blank" rel="noopener noreferrer">privacy policy</a>',
        termUrl: common.links.termsOfService,
        policyUrl: common.links.privacyPolicy
      },
      authButtons: {
        signInHere: 'Already have an account?',
        signUpHere: 'New to Spir?'
      },
      changeAccount: 'Change',
      changeAccountModal: {
        header: 'Change admin account?',
        body: 'You will need to select a different account as the administrator.',
        cancel: common.cancel,
        changeAccount: 'Change'
      },
      mainCalendarDesc: {
        title: 'Your team calendar',
        desc: 'Connect a calendar for your team to add and manage events.'
      },
      mainCalendarSelect: {
        noCalendarButton: 'Connect calendar'
      }
    },
    AuthCalendarSelectMeritBox: {
      notEnoughScope: {
        title: 'Please select all permissions',
        mainDesc: 'Spir could not access your calendar.',
        subDesc: 'We need to view and edit your calendar in order to show your availability and add new events.',
        nextStepButtonTitle: 'Try again'
      }
    }
  },
  calendarLists: {
    CalendarListPanel: {
      listName: common.calendar,
      gestUser: 'Guest',
      createMenuTitle: 'Create event'
    },
    CalendarListTeams: {
      title: common.teamCalendars,
      you: common.you,
      suspendedText: common.teamSuspended
    },
    CalendarListPersonal: {
      title: 'My calendars'
    }
  },
  confirm: {
    ConfirmEmailOtherTargetsForm: {
      addEmail: 'Add',
      email: common.emailAddress,
      label: 'Notification email',
      placeholder: 'email@example.com'
    },
    ConfirmLastActionBox: {
      cancel: 'Cancel',
      save: 'Confirm event'
    },
    ConfirmFillInRequestText: {
      title: 'Event information'
    },
    ConfirmCandidateRangeInfo: {
      changeDateButton: 'Modify'
    },
    ConfirmDurationChip: {
      hour: 'Duration: {hour}hour | Duration: {hour} hours',
      minute: 'Duration: {minute} minute | Duration: {minute} minutes'
    }
  },
  teamInvitation: {
    TeamInvitationAddCalendarButton: {
      addCalendarButtonTitle: 'Add calendar'
    },
    TeamInvitationAuthGuestAddCalendarModal: {
      title: 'Add calendar',
      confirmButton: 'Add'
    }
  },
  availabilitySharing: {
    AvailabilitySharingFormPanel: {
      TeamQuestionnaire: {
        title: 'Forms',
        selectedFormTitle: 'Selected form'
      },
      TeamParticipants: {
        attendeeAssignLogicTitle: 'Groups'
      }
    },
    AvailabilitySharingConfirmGuestCandidateListBox: {
      signUpButton: common.checkCandidatesOnCalendar,
      signUpAppeal: {
        description: 'Log into your Spir account to overlay these time slots on your own calendar.',
        button: 'Log in or sign up'
      }
    },
    AvailabilitySharingSegmentSelector: {
      title: `${common.personal} / ${common.team}`
    },
    AvailabilitySharingListBox: {
      zeroItemMessage: {
        title: 'Make life eventful',
        description:
          'Templates are reusable links that show your availability based on preset details. Your guests can schedule an event with you just by clicking on the link.<br/><a href="https://help.spirinc.com/en/share-availability-from-templates" class="i18n_link" target="_blank">Learn more about templates</a>',
        meritTitle: 'Effortless scheduling with templates',
        meritList: {
          autoExtraction: 'Automatically share your available times across all calendars.',
          receiver: 'Available time slots update as your calendar fills up, so you will never be double-booked.',
          repeat:
            'Use the same link to schedule events with multiple people. Send it over emails, or embed it in your website.'
        },
        createPromotion: 'Get started by creating your first template.',
        createPromotionMobile: 'Get started by creating your first template.'
      }
    },
    AvailabilitySharingListCardItem: {
      header: {
        moreButton: {
          publish: 'Publish',
          delete: common.delete,
          shareByEmail: 'Share via email',
          duplicate: 'Duplicate',
          openExternalTagCopyModal: 'Embed'
        }
      },
      body: {
        attendee: {
          label: common.attendee.label,
          count: '{count} person | {count} people'
        },
        duration: {
          label: common.duration
        },
        timeBuffer: {
          label: common.timeBuffer,
          display: '{timeBuffer} min'
        }
      },
      footer: {
        links: {
          urlCopy: 'Copy link',
          shareByEmail: 'Share via email',
          disabled: 'Publish this template to use it',
          openExternalTagCopyModal: 'Embed' // TODO: QUESTIONNAIRE_FEATURE (en)
        },
        editButton: 'View or edit'
      }
    },
    AvailabilitySharingForm: {
      maxNumPerDayHelpToolTipMessage: 'Maximum number of confirmed events before marking the day as unavailable'
    },
    AvailabilitySharingCustomEventTitle: {
      sectionTitle: 'Event title',
      formTitle: 'Title for individual events',
      editStartingButton: 'Customize',
      tooltip:
        'Customize the title displayed on the guest’s calendar, based on form responses or this template’s information.',
      validationError: 'The title has been reset to default because the form linked to this template was modified.',
      customEventTitleTemplate: {
        invitee: 'Guest name',
        organizer: 'Your name',
        candidateTitle: 'Template title'
      },
      modal: {
        title: 'Reset event title',
        description: 'The custom event title will be reset to default. Are you sure you want to proceed?',
        cancel: 'Cancel',
        clear: 'Reset'
      },
      slide: {
        title: 'Title for individual events',
        description:
          'Customize the title for individual events based on responses to the form linked to this template as well as other information. You can also add custom text that is added to all titles.',
        reset: 'Reset',
        cancel: 'Cancel',
        save: 'Save'
      }
    },
    AvailabilitySharingRooms: {
      title: 'Room settings',
      or: 'or',
      tip: {
        message: 'Find time slots based on the availability of resources, such as meeting rooms'
      },
      addButton: 'Add room or resource',
      unconnectedRooms:
        'There are no rooms or resources in your team. To use resources managed in Google Calendar, a team admin needs to add them to Spir.',
      unconnectedRoomsHelpLinkText: 'Learn about resources',
      unconnectedRoomsHelpLink: '#', // TODO:ROOM (リンク先)
      emptyRooms: 'You have added all available resources',
      prioritySelect: {
        priority: 'Priority'
      },
      priorityOrderChangeButton: 'Sort by priority',
      countNote: 'You can add up to 5 resources',
      deletedRoom: 'Deleted resource'
    },
    AvailabilitySharingTeamParticipants: {
      sectionTitle: 'Organizers info'
    },
    AvailabilitySharingTeamNotifications: {
      // TODO: translation (isInviteCalendarFeature)
      calendarInvitationSelectForm: {
        title: 'Invite the recipient to the calendar',
        options: {
          inviting: {
            label: 'Invite to the calendar',
            sub: 'Invite the recipient to events on the same calendar as the organizer.'
          },
          notInviting: {
            label: 'Do not invite to the calendar',
            sub: 'Do not invite the recipient to events on the same calendar as the organizer. Only a confirmation email will be sent.'
          }
        }
      }
    },
    AvailabilitySharingConfirmOperationBox: {
      // TODO: translation (isInviteCalendarFeature)
      slotSelectSectionTitle: 'Please select a slot'
    }
  },
  groupPolls: {
    GroupPollVoteButton: {
      confirm: common.confirm,
      noAvailableCandidate: common.noAvailableCandidate
    }
  },
  signup: {
    SignupGuide: {
      pleaseConfirmCandidate: common.pleaseConfirmCandidate
    }
  },
  unconfirmedLists: {
    UnconfirmedListStatusOperationBox: {
      label: {
        expired: 'Expired',
        requestedByConfirmer: 'New slots requested',
        suggestedByConfirmer: 'New slots suggested'
      },
      button: {
        setAlternativeCandidates: 'Offer new slots',
        confirmSuggestedCandidate: 'Confirm new slots',
        confirmSchedule: common.confirmSchedule
      }
    },
    UnconfirmedListEmptyMessage: {
      withPromotion: {
        title: "Let's start planning",
        description:
          'Events are shown here until their dates are confirmed by guests.<br />You can see group poll results and make changes to your events here.<br /><br />Create a one-time event for a greater control over the time slots you make available.<br /><a href="https://help.spirinc.com/en/suggest-times-to-schedule-scheduling" class="i18n_link" target="_blank">Learn more about one-time events</a>',
        meritTitle: 'Scheduling a one-time event with someone',
        meritList: {
          calendar: 'Automatically find your available times across all calendars.',
          share: 'Available time slots update as your calendar fills up, so you will never be double-booked.',
          onlineMeeting: 'Spir will add confirmed events to your calendar, and create a video call link.'
        },
        createPromotion: 'Get started by creating your first event.',
        createPromotionMobile: 'Get started by creating your first event.',
        calendarPageName: 'View my calendars'
      }
    }
  },
  changeRequested: {
    ChangeRequestedDate: {
      title: 'Request alternate times'
    },
    ChangeRequestedPageHeaderBox: {
      title: 'Alternate times requested',
      sentEmail: 'Spir sent an email to the organizer to request alternate time slots.',
      sendingEmail: 'You will get an email when there is an update.',
      notice: 'There is no need to further contact the organizer.'
    }
  },
  schedule: {
    ScheduleConfirmOperationBox: {
      confirmCandidateBlockTitle: common.confirmSchedule
    },
    ScheduleConfirmGuestCandidateListBox: {
      confirmCandidateBlockTitle: common.confirmSchedule,
      checkCandidatesOnCalendar: common.checkCandidatesOnCalendar
    },
    ScheduleConfirmChangeRequestActionBox: {
      title: common.noAvailableConfirmationDate,
      requestAlternativeButton: 'Request alternate times',
      suggestNewSlotsButton: common.suggestNewSlots
    },
    ScheduleConfirmSuggestSidePanelBox: {
      slotsSectionTitle: common.suggestNewSlots,
      addSlotButtonTitle: common.addPossibleDates,
      newSlotsDescription: 'Click the button below to suggest alternate time slots.'
    }
  },
  teamSettings: {
    TeamSettingSidePanel: {
      admin: {
        plan: common.plan,
        paymentInfo: common.paymentInfo,
        teamSetting: 'Organization profile',
        title: 'Admin settings',
        resource: 'Rooms or resources'
      },
      member: {
        canceling: 'Your Team Plan has been canceled and will expire on {date}.',
        canceled: 'Your Team Plan has expired. Subscribe to start using it again.'
      },
      team: {
        title: common.teamSetting,
        member: common.member,
        webhookSettings: 'Webhook settings'
      },
      personal: {
        title: 'Personal settings',
        myCalendar: 'Shared calendars'
      },
      teamHelpPage: {
        title: 'Spir for Teams guide',
        desc: 'Everything you need to know to get the most out of your team',
        url: common.links.teamHelpPage
      }
    },
    TeamSettingHeader: {
      plan: 'plan',
      trial: 'Free trial until {dueDate}',
      paymentFailed: 'Payment failed',
      trialExpired: 'Free trial ended',
      cancel: 'Team plan canceled',
      canceling: 'Team plan expired',
      pastDue: 'Payment overdue'
    },
    TeamSettingSubscriptionLaw: {
      header: common.registrationCaution.header,
      headerAttention: common.registrationCaution.headerAttention,
      freeTrial: common.registrationCaution.freeTrial,
      paymentTime:
        'Payment timing: Your credit card will be charged the total fee for the subscription period at the time of purchase. If you are offered a free trial, your credit card will be charged on the day after the free trial period. If you wish to continue using the selected plan after the initial subscription period, the credit card will be charged on the first day of the new subscription period. The credit card transaction date will vary based on the closing date and the terms of the credit card. Contact your credit card issuer for details.',
      paymentMethod: common.registrationCaution.paymentMethod,
      servicePeriod:
        'Service period: The selected plan will be available immediately after your credit card payment is authorized.',
      cancelRule:
        'Returns and cancellations: We do not accept returns after the purchase or renewal of subscriptions. Cancellations can be made at any time in accordance with the Terms of Service, but paid fees will not be refunded regardless of the remaining days in your subscription period. We also will not issue prorated refunds. Refer to the purchase page for products and services with special terms of sale or service.',
      howToCancel:
        'How to cancel: Refer to instructions <a class="registration-caution-ul__link" href="{link}" target="_blank" rel="noopener noreferrer">here</a>.',
      howToCancelUrl: 'https://www.notion.so/spir/WIP-How-do-I-cancel-my-subscription-e9ab7bb3999b4ac6bc919112d6acc018',
      others: common.registrationCaution.others,
      othersUrl: common.registrationCaution.othersUrl
    },
    TeamSettingPlanTableItemBox: {
      memberCountLimit: {
        lte: '<span class="member-count">{num} </span>members or less',
        gte: '<span class="member-count">{num} </span>members or more? Let\'s work together.'
      },
      available: '',
      orderButton: 'Subscribe',
      changePlanButton: 'Change plan',
      currentPlanButton: 'Current plan',
      contactButton: 'Contact sales',
      amount: common.priceAmount,
      taxIncluded: 'incl tax',
      recurringInterval: common.recurringInterval
    },
    TeamSettingPlanTableCollapse: {
      header: {
        title: 'Available plans',
        button: {
          expanded: 'Close',
          shrunk: 'Open'
        }
      },
      planNotes: {
        price: 'All pricing include taxes.',
        afterTrial: 'You will need to select a plan based on your team size when the trial ends.'
      },
      table: {
        memberCountLimit: {
          lte: 'members',
          gte: 'or more'
        },
        amount: common.priceAmount,
        recurringInterval: common.recurringInterval,
        ask: 'Contact sales'
      }
    }
  },
  teamAvailabilitySharing: {
    TeamAvailabilitySharingExternalTagCopyModal: {
      title: 'Embed in a website',
      description: 'Copy and paste the code below into your website HTML.', // Put link when the help page for english is prepared.
      codeFrame: {
        title: 'HTML code',
        copyButton: 'Copy'
      }
    },
    TeamAvailabilitySharingListAdjustment: {
      label: 'Filters',
      filterTitle: {
        search: 'Search',
        searchInputPlaceholder: 'Search by title'
      },
      member: {
        searchInputPlaceholder: 'Filter by person'
      },
      duration: {
        all: 'Show all'
      }
    },
    TeamAvailabilitySharingListBoxFilterConditionDisplay: {
      unit: 'Template | Template | Templates'
    },
    TeamAvailabilitySharingListBox: {
      zeroItemMessage: {
        title: 'Make life eventful',
        description:
          'Templates are reusable links that show your availability based on preset details. Your guests can schedule an event with you just by clicking on the link.<br/><a href="https://help.spirinc.com/en/share-availability-from-templates" class="i18n_link" target="_blank">Learn more about templates</a>',
        meritTitle: 'Effortless scheduling with templates',
        meritList: {
          autoExtraction: 'Automatically share you and your team members’ available times across all calendars.',
          receiver: 'Available time slots update as your calendar fills up, so you will never be double-booked.',
          repeat:
            'Use the same link to schedule events with multiple people. Send it over emails, or embed it in your website.'
        },
        createPromotion: 'Get started by creating your first template.',
        createPromotionMobile: 'Get started by creating your first template.'
      },
      emptyMessage: {
        title: 'No templates found',
        body: 'There aren’t any templates that match your filters.<br>Edit your filters and try again.'
      },
      messages: {
        successes: {
          duplicated: 'Template duplicated'
        },
        errors: {
          notMemberOfTeam: 'You must be a member of the team.'
        }
      }
    },
    TeamAvailabilitySharingConfirm: {
      cannotFetchCandidatesByAccountTokenInvalidError: common.accountTokenInvalidError.message
    }
  },
  teamAvailabilitySharingAfterConfirms: {
    TeamAvailabilitySharingAfterConfirmCardBox: {
      header: {
        canceled: {
          description: 'Spir sent an email to all attendees.',
          'description-mobile': 'Spir sent an email to all attendees.',
          title: 'Event canceled'
        },
        normal: {
          description: 'Spir sent an email to all attendees.',
          'description-mobile': 'Spir sent an email to all attendees.',
          title: 'Event confirmed: {publicTitle}'
        }
      },
      confirmUrl: {
        link: "If you would like to reschedule, please apply <a href='{href}' target='_blank' rel='noopener noreferrer'>here.</a>",
        'link-mobile':
          "If you would like to reschedule, please apply <a href='{href}' target='_blank' rel='noopener noreferrer'>here.</a>"
      },
      // TODO: translation (isInviteCalendarFeature)
      calendarEventButton: {
        google: 'Add event to Google',
        microsoft: 'Add event to Outlook'
      }
    }
  },
  integrationServices: {
    IntegrationServiceSlackChannelSelectForm: {
      title: 'Slack',
      tooltip: 'Select a channel to send notifications'
    },
    IntegrationServiceSlackChannelSelector: {
      select: {
        btn: 'Select'
      },
      channel: {
        nameLabel: 'Channel',
        btn: 'Remove'
      },
      errors: {
        userCancel: 'We need your permission to connect to your channel.',
        common: 'Could not connect to your channel.'
      }
    }
  },
  weekdays: {
    WeekdayConditionForm: {
      title: 'Weekly availability',
      weekdays: { ...common.weekdays }
    }
  },
  nav: {
    NavDrawer: {
      upgrade: '', // 表示されないので空文字
      otherLinks: {
        accountSettings: 'Account settings',
        profile: 'Profile',
        signOut: 'Log out'
      }
    },
    navTopBar: {
      helpActions: {
        help: 'Support',
        inquiry: 'Contact us'
      },
      invitationTip: 'Choose a plan', // TODO: プラン制限 英訳
      menus: {
        availabilitySharingList: common.nav.availabilityList,
        calendar: 'Calendar',
        confirmedList: common.nav.confirmedList,
        unconfirmedList: common.nav.unconfirmedList,
        forms: 'Forms',
        teamSettings: 'Team settings',
        invite: 'Invite',
        upgrade: '' // 表示されないので空文字
      }
    },
    navBottom: {
      menu: 'Menu',
      menus: {
        availabilitySharingList: common.nav.availabilityList,
        calendar: common.calendar,
        confirmedList: common.nav.confirmedList,
        unconfirmedList: common.nav.unconfirmedList,
        forms: 'Forms',
        teamSettings: 'Team settings',
        help: 'Help'
      }
    }
  },
  teamQuestionnaires: {
    TeamQuestionnairePreviewPanel: {
      title: 'Form preview'
    },
    TeamQuestionnaireSelectSlideOutPanels: {
      ListItem: {
        selectButton: {
          selected: 'Deselect',
          default: 'Select'
        }
      },
      title: 'Select a form',
      emptyList: {
        title: 'There are no forms yet.',
        description: 'Add forms to your templates to ask custom questions to your guests.'
      },
      footerBox: {
        cancel: 'Cancel',
        selected: 'Deselect'
      },
      previewPanel: {
        cancelButton: 'Close'
      },
      createNewFormButton: 'Create form'
    },
    TeamQuestionnaireSelectForm: {
      UnselectedTitleBox: {
        desc: {
          unselected:
            'Name and email are collected even if no forms are selected. | Name, email and message are collected even if no forms are selected.',
          failed: 'Forms unavailable.',
          default: 'Could not load forms.'
        }
      },
      DataLoaded: {
        reselectFormButton: 'Change form',
        defaultQuestions: common.questionnaires.defaultQuestions,
        footerBox: {
          selected: 'Preview',
          unselected: 'Select form'
        }
      }
    },
    card: {
      TeamQuestionnaireCardQuestions: {
        QuestionTitle: {
          defaultQuestions: common.questionnaires.defaultQuestions
        }
      }
    },
    TeamQuestionnaireMoreOptionButton: {
      title: 'Delete form',
      deleteConfirmationModal: {
        title: 'Remove this form?',
        description:
          'This form will be no longer linked to any templates. If it is being used to customize event titles, the titles will be reset to default.\nAre you sure you want to continue?'
      }
    },
    TeamQuestionnaireCardPreviewButton: {
      title: 'Preview'
    },
    TeamQuestionnaireQuestionMoreOptionButton: {
      title: 'Delete Question'
    },
    TeamQuestionnaireQuestionUiFormBox: {
      TitleForm: {
        title: 'Title',
        placeholder: 'Enter a title'
      },
      TypeSelectForm: {
        title: 'Question type',
        items: {
          textInput: 'Short text',
          textArea: 'Long text',
          phoneNumber: 'Phone number',
          emailAddress: 'Email address',
          checkboxes: 'Checkboxes'
        }
      },
      PlaceholderForm: {
        title: 'Placeholder text',
        placeholder: 'Enter text'
      },
      HelpTextForm: {
        title: 'Description',
        placeholder: 'Enter a description to provide additional information',
        addSection: {
          buttonTitle: 'Add description',
          description: 'Add a description to provide more information'
        }
      },
      RequiredForm: {
        title: 'Mark question as required',
        switchLabel: 'Required'
      },
      OptionsForm: {
        title: 'Options',
        description: 'Add more options to be selected',
        placeholder: 'Add an option',
        addButton: 'Add option',
        defaultOption: 'Option ',
        deleteConfirmationModal: {
          title: 'Remove this option?',
          description: "You can't undo this action. "
        }
      }
    },
    TeamQuestionnaireCreateButton: {
      title: 'Create form'
    },
    TeamQuestionnaireListConditionBox: {
      unit: 'Form | Form | Forms'
    },
    TeamQuestionnaireChangeQuestionsOrderCard: {
      placeholderKey: 'Placeholder',
      helpTextKey: 'Description'
    }
  },
  questionnaireAnswers: {
    QuestionnaireAnswerExtraAttendees: {
      title: 'Invite people',
      addingFieldOpenButton: 'Add email',
      form: {
        cancel: common.cancel,
        invite: 'Invite'
      }
    },
    QuestionnaireAnswerFields: {
      defaultQuestions: common.questionnaires.defaultQuestions,
      checkboxesQuestionForm: {
        requiredAtLeastOne: `Select one | Select one | ${common.requiredAtLeastPurposeCheck}`
      },
      emailQuestionForm: {
        connectionLink: 'Register with an email not linked to a Spir account',
        cancel: common.cancel,
        add: 'Add'
      }
    }
  },
  assignLogics: {
    AssignLogicAttendee: {
      or: 'or',
      description: 'Events will be assigned to one of the available group members.',
      orderChangeButton: 'Sort by priority',
      organizer: 'Organizer',
      group: {
        CalendarPreviewButton: {
          tooltip: 'Preview'
        },
        Group: {
          addButton: 'Add to group'
        },
        NewGroup: {
          addButton: 'New group'
        },
        GroupMenu: {
          delete: 'Delete'
        },
        GroupPriority: {
          priority: 'Priority',
          title: 'Select priority',
          description:
            'Events will be assigned to one of the available group members, starting from the highest priority group.'
        }
      },
      member: {
        MemberAddButton: {
          searchPlaceholder: 'Find people'
        }
      }
    }
  },
  events: {
    EventEditModal: {
      eventDeletionError: 'Event could not be deleted'
    }
  },
  teamEvents: {
    TeamEventMemberEditModal: {
      delete: 'Delete',
      edit: 'Edit',
      helpMessage: 'This will not be visible to guests.',
      cancelModal: {
        title: 'Are you sure you want to delete the event?',
        revertWarning: 'You are deleting an event in which you are not the organizer.',
        notificationWarning:
          'When you delete this event, all attendees will be notified. This action cannot be undone.',
        deleteMessageLabel: 'Reason for deletion',
        close: 'Cancel',
        cancel: 'Delete'
      }
    }
  }
}
