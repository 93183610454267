import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=3da358f5&scoped=true&"
import script from "./Item.vue?vue&type=script&lang=ts&"
export * from "./Item.vue?vue&type=script&lang=ts&"
import style0 from "./Item.vue?vue&type=style&index=0&id=3da358f5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.25.2__css_fhrrkcy3fgcmijahipwa25usca/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da358f5",
  null
  
)

export default component.exports